import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { BoldText } from '../../components/bold-text/bold-text';
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`We developers love convenience. We welcome any tool or feature that makes our lives easier. This is especially true
in regards to styling web content with CSS.`}</p>
    <p>{`One major challenge is to define CSS rules that target specific elements without writing
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/Specificity"
      }}>{`overly-specific selectors`}</a>{` that are hard to override.
Also, you don't want to couple your selectors too tightly to the DOM structure as it is prone to change.`}</p>
    <p>{`Various JavaScript frameworks have come up with different solutions for the problem: React uses
`}<a parentName="p" {...{
        "href": "https://create-react-app.dev/docs/adding-a-css-modules-stylesheet/"
      }}>{`CSS Modules`}</a>{`, which allow the scoping of CSS by
automatically creating a unique class name for a component's styles. In Angular, a component's styles are
`}<a parentName="p" {...{
        "href": "https://angular.io/guide/view-encapsulation"
      }}>{`encapsulated using custom HTML attributes`}</a>{` so that they don't affect the
rest of the application.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMBBf/EABYBAQEBAAAAAAAAAAAAAAAAAAMBAv/aAAwDAQACEAMQAAABpVp3lrsN/8QAGRAAAgMBAAAAAAAAAAAAAAAAAQIAAxEh/9oACAEBAAEFAkrAjrqNVhFcCx+N/8QAFxEBAAMAAAAAAAAAAAAAAAAAAQMQEv/aAAgBAwEBPwFkdFf/xAAYEQACAwAAAAAAAAAAAAAAAAAAAgERQf/aAAgBAgEBPwGV0tj/xAAaEAADAQADAAAAAAAAAAAAAAAAAREhAhJh/9oACAEBAAY/Am1DN2xmnM7e0iP/xAAcEAEAAgIDAQAAAAAAAAAAAAABACERUTFhkaH/2gAIAQEAAT8haYsdxAVtHyVK9uGqzcLCG+CaiE//2gAMAwEAAgADAAAAEC8v/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERUf/aAAgBAwEBPxCW0qP/xAAXEQEBAQEAAAAAAAAAAAAAAAABACFh/9oACAECAQE/EAeyG2//xAAdEAEBAAIBBQAAAAAAAAAAAAABEQAhQTFRYXHB/9oACAEBAAE/EFyjHejG89LjskdxUkYqQPF1PZiRewMIM2YR4chqyYdgngO/P3P/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A box with six donuts in different colors.",
          "title": "A box with six donuts in different colors.",
          "src": "/static/6dcdbd8a4856f0d8ffa5e42c0aadefd2/e5166/pexels-cottonbro-donuts.jpg",
          "srcSet": ["/static/6dcdbd8a4856f0d8ffa5e42c0aadefd2/f93b5/pexels-cottonbro-donuts.jpg 300w", "/static/6dcdbd8a4856f0d8ffa5e42c0aadefd2/b4294/pexels-cottonbro-donuts.jpg 600w", "/static/6dcdbd8a4856f0d8ffa5e42c0aadefd2/e5166/pexels-cottonbro-donuts.jpg 1200w", "/static/6dcdbd8a4856f0d8ffa5e42c0aadefd2/b17f8/pexels-cottonbro-donuts.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © cottonbro studio / pexels.com`}</em></p>
    <p>{`But why can't we scope styles with CSS alone? Actually, we can! The new `}<InlineCode mdxType="InlineCode">{`@scope`}</InlineCode>{` CSS at-rule
allows you to scope styles to specific DOM subtrees. You can even define lower bounds for the scope, creating a
so-called `}<ItalicText mdxType="ItalicText">{`donut scope`}</ItalicText>{`.`}</p>
    <p>{`I'll explain the basics of this new CSS feature and test its capabilities with an Angular demo application.`}</p>
    <h2>{`Demo: CSS Scope vs View Encapsulation`}</h2>
    <p>{`My `}<a parentName="p" {...{
        "href": "https://github.com/alexlehner86/test-css-scope"
      }}>{`demo application`}</a>{` was generated with Angular 17. It includes a header
and main section with some paragraphs, links, and several recipe cards.`}</p>
    <p>{`The `}<InlineCode mdxType="InlineCode">{`app-recipe-card`}</InlineCode>{` component (yellow background) uses the new CSS scope feature to only
target the HTML elements in its own subtree. Important: At the moment of writing this article,
`}<a parentName="p" {...{
        "href": "https://caniuse.com/css-cascade-scope"
      }}>{`scoped styles only work in Chrome, Edge, and Safari`}</a>{`!`}</p>
    <p>{`For comparison, I've also defined the `}<InlineCode mdxType="InlineCode">{`app-recipe-card-old`}</InlineCode>{` component (blue background), which
uses Angular's default view encapsulation. Go ahead and inspect the elements with your browser's developer tools.`}</p>
    <iframe src="https://alexlehner86.github.io/test-css-scope/" title="CSS Scope vs View Encapsulation Demo" loading="lazy"></iframe>
    <p>{`As the demo shows, CSS scope enables us to write simple selectors with low specificity and without the need of extra
class names. Let's take an in-depth look at how it all works.`}</p>
    <h2>{`The Basics of CSS Scope`}</h2>
    <p>{`The new CSS scope feature is defined in the `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-cascade-6/"
      }}>{`CSS Cascading and Inheritance Level 6`}</a>{`
module, which is still a working draft at the time of writing this post. It states:`}</p>
    <blockquote>
      <p parentName="blockquote">{`A `}<BoldText mdxType="BoldText">{`scope`}</BoldText>{` is a subtree or fragment of a document, which can be used by selectors for more targeted matching. A scope is formed by determining: The `}<BoldText mdxType="BoldText">{`scoping root node`}</BoldText>{`, which acts as the upper bound of the scope, and optionally the `}<BoldText mdxType="BoldText">{`scoping limit elements`}</BoldText>{`, which act as the lower bounds.`}</p>
    </blockquote>
    <p>{`In short, you need to define the root node of the DOM subtree you want to target. Optionally, you can also list one
or more inner elements that represent the lower boundary of your scope.`}</p>
    <h3>{`The @scope CSS at-rule`}</h3>
    <p>{`The `}<InlineCode mdxType="InlineCode">{`app-recipe-card`}</InlineCode>{` component in my demo includes headings, paragraphs, and an unordered list.
We style them with the following CSS code:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`@scope (app-recipe-card) {
    h3 {
        color: var(--highlight-color);
        font-size: 1.3rem;
    }

    p {
        margin-block: 0 1em;
    }

    ul {
        list-style: square;
        margin: 0;
        padding-inline-start: 1.25rem;
    }

    li::marker{
        color: var(--highlight-color);
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`The `}<InlineCode mdxType="InlineCode">{`@scope`}</InlineCode>{` block above defines `}<InlineCode mdxType="InlineCode">{`app-recipe-card`}</InlineCode>{` as
the `}<ItalicText mdxType="ItalicText">{`scoping root`}</ItalicText>{`, which determines the upper boundary of the subtree we want to target.
Now all contained style rules, like `}<InlineCode mdxType="InlineCode">{`h3 { ... }`}</InlineCode>{`, can only select from that limited subtree
of the DOM.`}</p>
    <h3>{`Creating a Donut Scope`}</h3>
    <p>{`In some cases, only setting a scoping root won't be enough. In `}<a parentName="p" {...{
        "href": "https://angular.io"
      }}>{`Angular`}</a>{` or `}<a parentName="p" {...{
        "href": "https://react.dev/"
      }}>{`React`}</a>{`,
you usually nest components within other components to create complex user interfaces. How can you make sure that the
parent component's styles don't affect its child components?`}</p>
    <p>{`The `}<InlineCode mdxType="InlineCode">{`@scope`}</InlineCode>{` at-rule also accepts a `}<ItalicText mdxType="ItalicText">{`scoping limit`}</ItalicText>{` which determines the
lower boundary. In my demo, the recipe cards are nested within the `}<InlineCode mdxType="InlineCode">{`app-recipes-list`}</InlineCode>{` component.
Here's part of its CSS code:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`@scope (app-recipes-list) to (app-recipe-card, app-recipe-card-old) {
    p {
        font-style: italic;
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`This way, we only italicize the paragraphs defined by the `}<InlineCode mdxType="InlineCode">{`app-recipes-list`}</InlineCode>{` parent component. The
paragraphs inside the child components `}<InlineCode mdxType="InlineCode">{`app-recipe-card`}</InlineCode>{` and `}<InlineCode mdxType="InlineCode">{`app-recipe-card-old`}</InlineCode>{`
are not affected.`}</p>
    <p>{`This type of scoping – with an upper and lower boundary – is called a `}<ItalicText mdxType="ItalicText">{`donut scope`}</ItalicText>{`. I recommend you
also read the article `}<a parentName="p" {...{
        "href": "https://developer.chrome.com/docs/css-ui/at-scope"
      }}>{`“Limit the reach of your selectors with the CSS @scope at-rule”`}</a>{`
by Bramus Van Damme. It includes great visualizations of different scope scenarios.`}</p>
    <h3>{`The :scope selector`}</h3>
    <p>{`Another useful feature is the `}<InlineCode mdxType="InlineCode">{`:scope`}</InlineCode>{` selector. It allows you to target the scoping root element
itself inside of the `}<InlineCode mdxType="InlineCode">{`@scope`}</InlineCode>{` block. Here's an example from my demo:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`@scope (app-recipe-card) {
    :scope {
        --highlight-color: rgb(194 34 2);
        display: block;
        background: lightgoldenrodyellow;
        color: black;
        padding: 1rem;
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <h3>{`Browser Support`}</h3>
    <p>{`What's that? You think that CSS scope is awesome and want to use it right away in all your projects? Unfortunately,
you'll have to be patient.`}</p>
    <p>{`At the moment, the `}<InlineCode mdxType="InlineCode">{`@scope`}</InlineCode>{` at-rule
is `}<a parentName="p" {...{
        "href": "https://caniuse.com/css-cascade-scope"
      }}>{`only supported by Chrome 118+, Edge 118+, and Safari 17.4+`}</a>{`.
Firefox doesn't support it yet, but Mozilla is `}<a parentName="p" {...{
        "href": "https://bugzilla.mozilla.org/show_bug.cgi?id=1830512"
      }}>{`actively working on the feature`}</a>{`.
Let's hope for cross-browser support sometime in 2024!`}</p>
    <h2>{`How to use @scope in an Angular application`}</h2>
    <p>{`In Angular, a `}<a parentName="p" {...{
        "href": "https://angular.io/guide/view-encapsulation"
      }}>{`component's styles are encapsulated by default`}</a>{`. The framework
creates custom HTML attributes like `}<InlineCode mdxType="InlineCode">{`_ngcontent-pmm-6`}</InlineCode>{`, adds them to the generated HTML elements
and modifies the component's CSS selectors so that they are only applied to the component's view.`}</p>
    <p>{`If you want to use the `}<InlineCode mdxType="InlineCode">{`@scope`}</InlineCode>{` at-rule instead, you need to manually switch off view encapsulation
for each component.`}</p>
    <h3>{`Switching Off View Encapsulation`}</h3>
    <p>{`To disable view encapsulation, you need to set `}<InlineCode mdxType="InlineCode">{`encapsulation`}</InlineCode>{` to `}<InlineCode mdxType="InlineCode">{`ViewEncapsulation.None`}</InlineCode>{`
in the `}<a parentName="p" {...{
        "href": "https://angular.io/api/core/Component"
      }}>{`component's decorator`}</a>{`. Here's an example from my demo:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`@Component({
    selector: 'app-recipe-card',
    standalone: true,
    templateUrl: './recipe-card.component.html',
    styleUrl: './recipe-card.component.css',
    encapsulation: ViewEncapsulation.None,
})
export class RecipeCardComponent {
    @Input({ required: true }) recipe!: Recipe;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Now your CSS selectors won't be extended with custom attributes and the component styles are applied globally.
You'll need to use the `}<InlineCode mdxType="InlineCode">{`@scope`}</InlineCode>{` at-rule instead to encapsulate the styles.`}</p>
    <h3>{`Comparing the HTML and CSS output`}</h3>
    <p>{`As we've seen, using CSS scope requires a little bit of effort when creating a component. Simply using Angular's default
view encapsulation instead would be more convenient.`}</p>
    <p>{`But I would argue that the advantages of CSS scope far exceed this minor inconvenience. Let's take a look at the HTML and CSS
generated for the `}<InlineCode mdxType="InlineCode">{`app-recipe-card`}</InlineCode>{` component in my demo:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`/* HTML elements in the DOM */
<app-recipe-card>
    <h3>Pizza Margherita</h3>
    <p>The best pizza in town!</p>
    <h4>Ingredients</h4>
    <ul>
        <li>Cutting edge CSS features!</li>
        /* More list items */
    </ul>
</app-recipe-card>

/* Generated CSS code (excerpt) */
@scope (app-recipe-card) {
    h3 {
        color: var(--highlight-color);
        font-size: 1.3rem;
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Let's compare this to the HTML and CSS generated for the `}<InlineCode mdxType="InlineCode">{`app-recipe-card-old`}</InlineCode>{` component:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<app-recipe-card-old _nghost-ng-c2291633987>
    <h3 _ngcontent-ng-c2291633987>Pizza Margherita (Old)</h3>
    <p _ngcontent-ng-c2291633987>The (second) best pizza in town!</p>
    <h4 _ngcontent-ng-c2291633987>Ingredients</h4>
    <ul _ngcontent-ng-c2291633987>
        <li _ngcontent-ng-c2291633987>DOM cluttering view encapsulation</li>
        /* More list items */
    </ul>
</app-recipe-card-old>
    
/* Generated CSS code (excerpt) */
h3[_ngcontent-ng-c2291633987] {
    color: var(--highlight-color);
    font-size: 1.3rem;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Now imagine you need to debug your application. The recipe card isn't rendered the way you intended. You open your browser's
developer tools and inspect the DOM and the applied styles. Which code would be easier to read and understand?`}</p>
    <p>{`I think that the myriad of `}<InlineCode mdxType="InlineCode">{`_ngcontent-ng-c2291633987`}</InlineCode>{` attributes would be very distracting. I think
that my mind would find it easier to process the `}<InlineCode mdxType="InlineCode">{`@scope (app-recipe-card) { h3 }`}</InlineCode>{` selector than
the `}<InlineCode mdxType="InlineCode">{`h3`}{`[_ngcontent-ng-c2291633987]`}</InlineCode>{` selector. 😉`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`Getting back to my initial question: Will the CSS scope feature replace Angular's view encapsulation? Maybe. I really don't
know. Ideally, the Angular team will adapt their default view encapsulation mechanism to use the `}<InlineCode mdxType="InlineCode">{`@scope`}</InlineCode>{`
at-rule.`}</p>
    <p>{`Regardless of what the Angular team does: It's already pretty easy to switch off view encapsulation for a component and define
the styles inside a `}<InlineCode mdxType="InlineCode">{`@scope`}</InlineCode>{` block. The generated HTML and CSS code has better readability and is far
easier to debug. It also contributes to a reduced bundle size of your web application.`}</p>
    <p>{`For me, one thing is certain: As soon as CSS scope has cross-browser support, I'll be using it in my projects. 🤩`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      